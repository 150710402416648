const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6921176', space_js: '//yfcpsb.yuncsnet.com/site/kfen/u/o/source/og_e.js' }];
const DIALOG = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921177', space_js: '//yfcpsb.yuncsnet.com/site/lg-fovp/common/p-h/static/h.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921178', space_js: '//yfcpsb.yuncsnet.com/common/mh_gp_wqq_ij.js' }];
const NATIVE = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921179', space_js: '//yfcpsb.yuncsnet.com/production/ni/hqx/resource/r/production/r/common/jq.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_dialog: DIALOG,
    Home_banner: BANNER,
    Topic_native: NATIVE,
    Discover_native: NATIVE,
    Hot_native: NATIVE,
    Rank_native: NATIVE,
    TopicDetail_inter: INTER,
    TopicDetail_dialog: DIALOG,
    TopicDetail_banner: BANNER,
    TopicResult_inter: INTER,
    TopicResult_dialog: DIALOG,
    TopicResult_native: NATIVE,
    TopicResult_banner: BANNER,
};
